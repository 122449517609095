<template>
    <RowWrap width="auto" :class="[{ 'is-empty': mWorkingTimes.length === 0 }]">
        <template #header>
            <Row
                v-if="mWorkingTimes.length > 0"
                :parentHover="false"
                :items="{ row: header }"
                :key="-1"
                :index="-1"
                @onClickColumn="e => handleHeaderClick(e)"
                class="Row-Header"
            />
        </template>
        <Row
            v-for="(workingTime, i) in mWorkingTimes"
            :items="workingTime"
            :hasClick="true"
            :key="i"
            :index="i"
            @onHover="activeRow = i"
            @onLeave="activeRow = -1"
            @onClick="e => $emit('onClick', workingTime.driverNumber, e)"
        />
        <EmptyState v-if="mWorkingTimes.length === 0" />
    </RowWrap>
</template>

<script type="text/javascript">
import Row from '@/components/Row';
import { getDuration } from '@/lib/helper';
import EmptyState from '@/components/EmptyState.vue';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'WorkingTimesTable',
    components: {
        RowWrap,
        Row,
        EmptyState,
    },
    props: {
        workingTimes: {
            type: Array,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            activeBar: -1,
            sortState: {
                column: 'Fahrer',
                ascending: true,
            },
        };
    },

    computed: {
        header() {
            return [
                { name: 'Fahrer', value: null },
                { name: 'Fahrer Nummer', value: null },
                { name: 'Schichtzeit', value: null },
                { name: 'Arbeitszeit', value: null },
                { name: 'Pause', value: null },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mWorkingTimes() {
            return this.workingTimes.length > 0
                ? this.sortWorkingTimes([...this.formatWorkingTimes(this.workingTimes)])
                : [];
        },
    },
    methods: {
        handleRandomName() {
            const { fakerDE, fakerTR, fakerAR } = require('@faker-js/faker');

            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 2)];
        },
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();

            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        sortWorkingTimes(arr) {
            const { column, ascending } = this.sortState;
            return arr.sort((a, b) => {
                const getVal = (row, col) => {
                    const entry = row.row.find(item => item.name === col);
                    return entry?.rawValue || '';
                };

                const aVal = getVal(a, column);
                const bVal = getVal(b, column);

                if (typeof aVal === 'number' && typeof bVal === 'number') {
                    return ascending ? aVal - bVal : bVal - aVal;
                }

                if (aVal instanceof Date && bVal instanceof Date) {
                    return ascending ? aVal - bVal : bVal - aVal;
                }

                return ascending
                    ? String(aVal).localeCompare(String(bVal))
                    : String(bVal).localeCompare(String(aVal));
            });
        },
        hasPauses(w) {
            return w.pause?.length > 0;
        },

        formatWorkingTimes(workingTimes) {
            return workingTimes.map(workingTime => {
                return {
                    pause: workingTime.pause,
                    driverNumber: workingTime.driverNumber,
                    row: [
                        {
                            name: 'Fahrer',
                            value: `${workingTime?.driverName || ''}`,
                            rawValue: workingTime.driverName || '',
                        },
                        {
                            name: 'Fahrer Nummer',
                            value: `${workingTime?.driverNumber || ''}`,
                            rawValue: workingTime.driverNumber || '',
                        },
                        {
                            name: 'Schichtzeit',
                            value: getDuration(workingTime.shiftTime),
                            rawValue: workingTime.shiftTime || 0,
                        },
                        {
                            name: 'Arbeitszeit',
                            value: getDuration(workingTime.workTime),
                            rawValue: workingTime.workTime || 0,
                        },
                        {
                            name: 'Pause',
                            value: getDuration(workingTime.pauseTime),
                            rawValue: workingTime.pauseTime || 0,
                        },
                    ],
                };
            });
        },
    },
};
</script>
