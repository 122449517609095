<template>
    <RowWrap :class="[{ 'is-empty': mWorkingTimes.length === 0 }]">
        <Row
            v-if="mWorkingTimes.length > 0"
            :parentHover="false"
            :items="{ row: header }"
            :key="-1"
            :index="-1"
            @onClickColumn="handleHeaderClick"
            class="Row-Header"
        />
        <Row
            v-for="(workingTime, i) in mWorkingTimes"
            :items="workingTime"
            :hasClick="true"
            :key="i"
            :index="i"
            :hasBadge="hasPauses(workingTime)"
            @onHover="activeRow = i"
            @onLeave="activeRow = -1"
            @onClick="e => $emit('onClick', e)"
        />
        <EmptyState v-if="mWorkingTimes.length === 0" />
    </RowWrap>
</template>

<script>
import { format } from 'date-fns';
import { getDuration } from '../../../../lib/helper';
import Row from '@/components/Row';
import EmptyState from '@/components/EmptyState.vue';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'WorkingTimesTable',
    components: {
        RowWrap,
        Row,
        EmptyState,
    },
    props: {
        workingTimes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            activeRow: -1,
            sortState: {
                column: 'Datum',
                ascending: true,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Datum', value: null },
                { name: 'Schichtzeit', value: null },
                { name: 'Arbeitszeit', value: null },
                { name: 'Pause', value: null },
                { name: 'Stunden 20-6 uhr', value: null },
                { name: 'Stunden 0-4 uhr', value: null },
                { name: 'Sonntag', value: null },
                { name: 'Feiertag 125%', value: null },
                { name: 'Feiertag 150%', value: null },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mWorkingTimes() {
            return this.workingTimes.length > 0
                ? this.sortWorkingTimes([...this.formatWorkingTimes(this.workingTimes)])
                : [];
        },
    },
    methods: {
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        sortWorkingTimes(arr) {
            const { column, ascending } = this.sortState;
            return arr.sort((a, b) => {
                const getVal = (row, col) => {
                    const entry = row.row.find(item => item.name == col);
                    return entry?.rawValue || entry?.value || '';
                };

                const aVal = getVal(a, column);
                const bVal = getVal(b, column);
                console.log({ aVal, bVal });

                if (aVal instanceof Date && bVal instanceof Date) {
                    return ascending ? aVal - bVal : bVal - aVal;
                } else if (typeof aVal === 'number' && typeof bVal === 'number') {
                    return ascending ? aVal - bVal : bVal - aVal;
                } else {
                    return ascending
                        ? String(aVal).localeCompare(String(bVal))
                        : String(bVal).localeCompare(String(aVal));
                }
            });
        },
        hasPauses(w) {
            return w.pause?.length > 0;
        },
        formatWorkingTimes(workingTimes) {
            return workingTimes.map(workingTime => {
                return {
                    pause: workingTime.pause,
                    row: [
                        {
                            name: 'Datum',
                            value: format(new Date(workingTime?.date), 'dd.MM.yyyy'),
                            rawValue: new Date(workingTime?.date),
                        },
                        {
                            name: 'Schichtzeit',
                            value: workingTime.shiftTime
                                ? getDuration(workingTime.shiftTime)
                                : '--:--',
                            rawValue: workingTime.shiftTime || 0,
                        },
                        {
                            name: 'Arbeitszeit',
                            value: workingTime.workTime
                                ? getDuration(workingTime.workTime)
                                : '--:--',
                            rawValue: workingTime.workTime || 0,
                        },
                        {
                            name: 'Pause',
                            value: workingTime.pauseTime
                                ? getDuration(workingTime.pauseTime)
                                : '--:--',
                            rawValue: workingTime.pauseTime || 0,
                        },
                        {
                            name: 'Stunden 20-6 uhr',
                            value: workingTime.eightToSix
                                ? getDuration(workingTime.eightToSix)
                                : '--:--',
                            rawValue: workingTime.eightToSix || 0,
                        },
                        {
                            name: 'Stunden 0-4 uhr',
                            value: workingTime.zeroToFour
                                ? getDuration(workingTime.zeroToFour)
                                : '--:--',
                            rawValue: workingTime.zeroToFour || 0,
                        },
                        {
                            name: 'Sonntag',
                            value: workingTime.sunday ? getDuration(workingTime.sunday) : '--:--',
                            rawValue: workingTime.sunday || 0,
                        },
                        {
                            name: 'Feiertag 125%',
                            value: workingTime.holiday125
                                ? getDuration(workingTime.holiday125)
                                : '--:--',
                            rawValue: workingTime.holiday125 || 0,
                        },
                        {
                            name: 'Feiertag 150%',
                            value: workingTime.holiday150
                                ? getDuration(workingTime.holiday150)
                                : '--:--',
                            rawValue: workingTime.holiday150 || 0,
                        },
                    ],
                };
            });
        },
    },
};
</script>
