var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RowWrap', {
    class: [{
      'is-empty': _vm.mWorkingTimes.length === 0
    }]
  }, [_vm.mWorkingTimes.length > 0 ? _c('Row', {
    key: -1,
    staticClass: "Row-Header",
    attrs: {
      "parentHover": false,
      "items": {
        row: _vm.header
      },
      "index": -1
    },
    on: {
      "onClickColumn": _vm.handleHeaderClick
    }
  }) : _vm._e(), _vm._l(_vm.mWorkingTimes, function (workingTime, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "items": workingTime,
        "hasClick": true,
        "index": i,
        "hasBadge": _vm.hasPauses(workingTime)
      },
      on: {
        "onHover": function onHover($event) {
          _vm.activeRow = i;
        },
        "onLeave": function onLeave($event) {
          _vm.activeRow = -1;
        },
        "onClick": function onClick(e) {
          return _vm.$emit('onClick', e);
        }
      }
    });
  }), _vm.mWorkingTimes.length === 0 ? _c('EmptyState') : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }